<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar class="add-new-data-sidebar items-no-padding" click-not-close color="primary" default-index="1"
              parent="body"
              position-right spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? 'ADD NEW' : 'UPDATE' }} FAQ</h4>
      <feather-icon @click.stop="isSidebarActiveLocal = false" class="cursor-pointer" icon="XIcon"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" :key="$vs.rtl" :settings="settings" class="scroll-area--data-list-add-new">

      <div class="p-6">


        <!-- QUESTION -->
        <vs-textarea rows="3" class="mt-5 w-full" label="Question" name="item-question" v-model="dataQuestion" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('item-question')">{{errors.first('item-question')}}</span>

        <!-- ANSWER -->
        <vs-textarea rows="9" class="mt-5 w-full" label="Answer" name="item-answer" v-model="dataAnswer" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('item-answer')">{{errors.first('item-answer')}}</span>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button :disabled="!isFormValid" @click="submitData" class="mr-6">Submit</vs-button>
      <vs-button @click="isSidebarActiveLocal = false" color="danger" type="border">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {

      dataId: null,
      dataQuestion: '',
      dataAnswer: '',

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const {id, question, answer} = JSON.parse(JSON.stringify(this.data));
        this.dataId = id;
        this.dataQuestion = question;
        this.dataAnswer = answer;
        this.initValues();
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar');
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    isFormValid() {
      return !this.errors.any() && this.dataQuestion && this.dataAnswer;
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  methods: {
    initValues() {
      if (this.data.id) return;
      this.dataId = null;
      this.dataQuestion = '';
      this.dataAnswer = '';
    },
    submitData() {
      this.$validator.validateAll().then(result => {

        this.$vs.loading();

        if (result) {
          const obj = {
            id: this.dataId,
            question: this.dataQuestion,
            answer: this.dataAnswer,
          };

          if (this.dataId !== null && this.dataId >= 0) {

            this.$http
              .post(`faqs/${this.dataId}`, obj)
              .then((response) => {

                this.$parent.loadFaqBuffer();
                this.$emit('closeSidebar')

                return this.$vs.notify({
                  color: 'success',
                  title: 'Modified FAQ',
                  text: 'Your FAQ has been modified',
                });
              })
              .catch(exception => {
                this.$vs.loading.close();

                let details = 'An unknown error occurred while modifying the FAQ. This has been reported.';

                if (exception.response) {

                  const response = exception.response;

                  if (response.status === 400) {
                    details = response.data.error.description;
                  }

                }

                return this.$vs.notify({
                  title: 'Failed to modify FAQ',
                  text: details,
                  color: 'danger',
                  position: 'top-right',
                });
              });

          } else {
            this.$http
              .post('faqs', obj)
              .then((response) => {
                this.$parent.loadFaqBuffer();
                this.$emit('closeSidebar')

                return this.$vs.notify({
                  color: 'success',
                  title: 'Created FAQ',
                  text: 'Your FAQ has been created',
                });
              })
              .catch(exception => {
                this.$vs.loading.close();

                let details = 'An unknown error occurred while creating the FAQ. This has been reported.';

                if (exception.response) {

                  const response = exception.response;

                  if (response.status === 400) {
                    details = response.data.error.description;
                  }

                }

                return this.$vs.notify({
                  title: 'Failed to create FAQ',
                  text: details,
                  color: 'danger',
                  position: 'top-right',
                });
              });
          }

        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
