<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <feather-icon @click="initiateUpdate" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"/>
    <feather-icon @click="confirmDeleteRecord" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer"/>
  </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    initiateUpdate() {
      this.params.initiateEdit({
        id: this.params.data.id,
        question: this.params.data.question,
        answer: this.params.data.answer,
      })
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',

        title: 'Confirm Deletion',
        text: `Are you sure you want to delete this FAQ?`,
        accept: this.deleteRecord,
        acceptText: 'Delete',
      });
    },
    deleteRecord() {

      this.$vs.loading();

      this.$http.delete(`faqs/${this.params.data.id}`)
        .then(response => {

          if (response.status === 204) {
            this.params.loadFaqBuffer();

            return this.$vs.notify({
              color: 'success',
              title: 'FAQ Delete',
              text: 'You have successfully delete this faq',
              position: 'top-right',
            });
          }

          this.showError({response});
        })
        .catch(this.showError);

    },
    showError(exception) {

      this.$vs.loading.close();

      let error = 'An unknown error occurred while deleting this faq';
      if (exception.response) {
        error = exception.response.data.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to delete faq',
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
  },
};
</script>
